import React from 'react'
import Link from 'gatsby-link'
import './style.css'
import twitter from '../../../assets/images/tw.png'
import li from '../../../assets/images/li.png'
import home from '../../../assets/images/home.svg'



// import Timeline from 'react-timeline-semantic-ui';

class EducateDeath extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    //    option: 'intro'
    }
    this.toggleOption = this.toggleOption.bind(this);
  }

  toggleOption(clicked) {
    // console.log(clicked);
    // if (clicked == "death-prep") {
    //    this.setState({option:"death-prep"})
    // }
    // if (clicked == "funeral-costs") {
    //   this.setState({option:"funeral-costs"})
    // }

    // if (clicked == "intro") {
    // this.setState({option:"intro"})
    // }
  }

  render() { 
    return (
      <div className='ed-death-outer'>
        <div className="de-header">
            DEATH <span className="clear-char">P</span>REPA<span className="clear-char">I</span>RED
            <Link to="/table-of-contents"><img className="de-home" src={home} /></Link>
        </div>
        <div className="checklist-container">
        <div className="de-step">1. START SMALL: Give people a call to action, like a simple checklist on your website.</div>
            {/* <h4>For healthy people:</h4> */}
            <ul className="dotted ed-checklist">
                <li><div className="c-box"></div>Do you want to be DNR or Full code if you get taken to the E.R. and are unresponsive?</li>
                <li><div className="c-box"></div>Would you like to donate your body or your organs?</li>
                <li><div className="c-box"></div>What do you want done with your belongings?</li>
                <li><div className="c-box"></div>What do you want done with your digital footprint?</li>
                <li><div className="c-box"></div>Does someone have access to your accounts that might need it for cancellations?</li>
                <li><div className="c-box"></div>How do you want your body disposed of?</li>
                <li><div className="c-box"></div>What kind of funeral do you want?</li>
                <li><div className="c-box"></div>Have you talked to your family about death?</li>
            </ul>
        </div>
        <div className="code-status-outer">
            <div className="de-step-2">2. EDUCATE. Promote education and conversation around death. Even small things like code status could make a difference.</div>
            
            {/* <div className="cs-wrapper cs-dnr"> */}
                <div className='cs-wrapper-title'>
                    {/* <br /> */}
                    <div className="cs-w-title-c">D</div>
                    <div className="cs-w-title-c">N</div>
                    <div className="cs-w-title-c">R</div>
                    <br />
                    <div className="cs-w-title-s">o</div>
                    <div className="cs-w-title-s">r</div>
                    <br />
                    <div className="cs-w-title-t">F</div>
                    <div className="cs-w-title-t">U</div>
                    <div className="cs-w-title-t">L</div>
                    <div className="cs-w-title-t">L</div>
                    <div className="cs-w-title-t">C</div>
                    <div className="cs-w-title-t">O</div>
                    <div className="cs-w-title-t">D</div>
                    <div className="cs-w-title-t">E</div>
                </div>
                <div className="cs-wrapper-text">
                    <p>Code status refers to type of treatment a person would or would not desire if their heart were to stop beating or if they were no longer breathing on their own. </p>
                    <div className="cs-means">Full Code means</div>
                    <ul>
                        <li>Cardiopulmonary resuscitation (CPR) which means chest compressions. Often results in broken ribs.</li>
                        <li>Shocks which consists of shocking the individual w/ 200-360 Joules. This is an attempt to electrically jumpstart the heart</li>   
                        <li>Cardiac arrest medications. Provides an artificial means to attempt to chemically support the heart</li>  
                        <li>Intubation w/ mechanical ventilation involves placing a breathing tube that connects to a breathing machine</li>          
                    </ul>    
                    <div className="cs-means">DNR or DNAR means</div>
                    <p>DNAR or DNR stands for "do not attempt resuscitation."  It does NOT mean withholding any type of medical treatment (such as antibiotics, fluids). It means avoiding the interventions listed above. It does NOT mean do nothing. In cases where E.R. doctors don't think a patient can be saved, instead of putting them through full code trama they can administer comfort care that will allow the patient to spend their last hours with loved ones in as little pain as possible.</p>
                    <div className="cs-means">Why would anyone want to avoid the interventions listed above? </div>
                    <ul>
                        <li>Low chance of success</li>
                        <li>If patients survive, it is often w/ severe disability, they don’t return to the life they had.</li>
                        <li>The interventions are violent and often end with patients dying on the operating table unable to say goodbye to their loved ones.</li>
                    </ul>
                 <div className='cs-wrapper-title-2'>AT WHAT COST?</div>
         
                {/* </div> */}
            </div>
        </div>
          
    
        <div className="de-additional-info">
        <div className="de-step-3">3. Death Meditation</div>
          <Link to="/word-game"><h4 className="dm">Death Word Meditation</h4></Link>
        </div>
        <div className="de-contact">
                <div> If you are interested in further exploring what you can do to make a positive impact regarding death in your community, or if you otherwise are interested in working together contact me at <a href="tomrau@gmail.com">tomrau@gmail.com</a>. Or feel free to connect with me on <a href="linkedin">LinkedIn</a> or <a href="www.twitter.com/hifilorau">Twitter</a></div>
                <div className="de-social">
                    <h5>Share and Follow</h5>
                    <a href="https://www.twitter.com/hifilorau"><img src={twitter}/></a>
                    <a href="https://www.linkedin.com/in/tom-rau/"><img src={li}/></a>
                </div>
        </div>

       {/* <div className="de-trends-outer">
            <h4 className="cs-trends-title">TRENDS</h4>
            <ul className="de-trends">
                <li>Funeral Costs</li>
                <li>Rising Suicide Rate</li>
                <li>End of life medical costs</li>
                <li>New/changing laws and regulations</li>
            </ul>
        </div>   */}
        <div className="de-resources">
            <h4 className="de-subheader">Resources & Links.</h4>
            <ul className="de-resources-ul">
                <li><a href="https://www.oktodie.com/">Ok to Die</a></li>
                <li><a href="">National Home Funeral Alliance</a></li>
                <li><a href="http://www.orderofthegooddeath.com">Order of the Good Death</a></li>
                <li><a href="">National Home Funeral Alliance</a></li>
                <li><a href="https://www.freewill.com/">Freewill</a></li>
            </ul>
        </div>
      </div>
    )
  }

}

export default EducateDeath
